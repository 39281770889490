
/* Container styling */
.incidents-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Form styling */
.incidents-container .incident-form input,
.incidents-container .incident-form select {
    width: calc(100% - 20px);
    padding:  5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.incidents-container .incident-form button {
    width: 100%;
    padding:  5px 10px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.incidents-container .incident-form button:hover {
    background-color: #0056b3;
}

/* Accordion styling */
.accordion .chakra-accordion__button {
    font-size: 18px !important;
    padding: 7.5px !important;
    border-radius: 4px !important;
    margin-bottom: 5px !important;
    cursor: pointer;
    transition: background-color 0.3s !important;
}

.accordion .chakra-accordion__button:hover {
    background-color: #d5d5d5;
}

.accordion .chakra-accordion__panel {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    padding: 10px !important;
    margin-bottom: 10px !important;
}

.incidents-container .incident-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.incidents-container .incident-item:last-child {
    border-bottom: none;
}

.incidents-container .incident-item strong {
    color: #333 !important;
    font-weight: bold !important;
}

/* Buttons inside the incidents */
.incidents-container .incident-item button {
    padding:  5px 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
    font-size: 14px;
}

.incidents-container .incident-item button:nth-child(1) {
    background-color: #ffc107;
    color: #333;
    margin-right: 10px;
}

.incidents-container .incident-item button:nth-child(1):hover {
    background-color: #e0a800;
}

.incidents-container .incident-item button:nth-child(2) {
    background-color: #dc3545;
    color: white;
}

.incidents-container .incident-item button:nth-child(2):hover {
    background-color: #c82333;
}

/* Heading */
.incidents-container h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #e9ecef;
    color: #333;
}

.incidents-container ul{
    margin: 0;
}

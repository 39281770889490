/* App.css */
.App {
  display: flex;
  height: 100vh; /* Full viewport height */
}

h1, h2{
  color: #444;
}

.sidebar {
  width: 200px; /* Fixed sidebar width */
  background-color: #f8f9fa;  /* Light grey background for the sidebar */
  color: #333;                /* Darker text for readability */
  border-right: 1px solid #ddd; /* Subtle right border for separation */
}

.sidebar-title {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: #2c3e50;                 /* Dark text color for visibility */
  border-bottom: 1px solid #ddd; /* Light line to separate the title from items */
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  /* width: 100%; */
}

.sidebar nav ul li a {
  display: block;
  text-decoration: none;
  color: #555;                /* Slightly lighter text color */
  padding: 3px 12px;         /* Padding for link elements */
  transition: background-color 0.2s, color 0.2s; /* Smooth transition for hover effects */
}

.sidebar nav ul li a:hover,
.sidebar nav ul li a.active {
  background-color: #dedede;  /* Light grey background on hover */
  color: #000;                /* Darker text color on hover for better visibility */
}

.content {
  flex-grow: 1; /* Take up the remaining space */
  overflow-y: auto; /* Enable scrolling if content is taller than the screen */
  padding: 20px;
  background-color: #ffffff;  /* White background for the main content area */
}

.App-header {
  padding: 10px;
  background-color: #f0f0f0;  /* Light grey background for the header */
  border-bottom: 1px solid #ddd; /* Subtle line to separate the header from content */
}

.sidebar nav ul li a.active {
  background-color: #dedede;  /* Light grey background on hover or active */
  color: #000;                /* Darker text color on hover or active */
}


/* Optional: Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width sidebar on small screens */
    border-right: none;
    border-bottom: 1px solid #ddd; /* Border at the bottom instead of the right */
  }

  .sidebar nav ul li a {
    padding: 10px; /* Slightly reduced padding on small screens */
  }
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-neutral-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-neutral-100);
  --amplify-components-button-link-color: var(--amplify-colors-neutral-80);
}

/* EmployeesRank.css */
.employees-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.employees-container h1, .employees-container h2 {
    color: #444;
    text-align: center;
}

.employees-container p{
    margin: 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.employee-item {
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    font-size: 1.40rem;
}

.employee-item:hover {
    transform: translateY(-3px);
}

li {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    transition: transform 0.2s ease-in-out;
}

li:hover {
    transform: scale(1.02);
}

/* Removes default link styles from all <Link> components in the application */
a {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits color from parent */
}

/* Specific to links within the employees-container, if you want to limit the scope */
.employees-container a {
    text-decoration: none;
    color: inherit; /* Ensures the link color matches the dynamically set color */
    font-weight: normal; /* Optional: Adjusts the weight of the font */
}

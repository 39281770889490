.employees-content h3, .employees-content h2{
    margin: 10px auto;
}

/* General styling for the task list */
.tasks-list {
    list-style-type: none; /* Removes default list styling */
    padding: 0; /* Removes default padding */
    margin: 0; /* Removes default margin */
}

/* Task item styling */
.tasks-list li {
    background: #f9f9f9; /* Light grey background for each task */
    padding: 15px;
    margin-bottom: 10px; /* Space between tasks */
    border-radius: 5px; /* Rounded corners for task items */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    position: relative; /* Needed for absolute positioning of the link */
    color: #555;
}

/* Styling for task title */
.tasks-list span.title {
    font-size: 1.15rem;
    color: #333;
}

/* Styling for rationale text */
.tasks-list li .rationale {
    font-style: italic;
    color: #95a5a6; /* default light gray for less important scores */
}

/* Dynamic styling based on score */
.tasks-list li.score-1 .rationale { color: #2c3e50; } /* Green */
.tasks-list li.score-3 .rationale { color: #2980b9; } /* Blue */
.tasks-list li.score-5 .rationale { color: #f39c12; } /* Yellow */
.tasks-list li.score-7 .rationale { color: #d35400; } /* Orange */
.tasks-list li.score-10 .rationale { color: #c0392b; } /* Red */

/* Styling for links (e.g., "View Task") */
.tasks-list li a {
    position: absolute; /* Positions the link absolutely within the li */
    right: 10px; /* 10px from the right edge */
    top: 10px; /* Aligns with the top of the li */
    background-color: #3498db; /* Vibrant blue background */
    color: white; /* White text color */
    padding: 5px 10px; /* Padding around the text */
    text-decoration: none; /* Removes underline from the link */
    border-radius: 3px; /* Rounded corners for the link */
}

.tasks-list li a:hover {
    background-color: #2980b9; /* Darker blue on hover */
}

/* Dynamic background colors for links based on score */
.tasks-list li.score-1 a { background-color: #2c3e50; } /* Green */
.tasks-list li.score-3 a { background-color: #2980b9; } /* Blue */
.tasks-list li.score-5 a { background-color: #f39c12; } /* Yellow */
.tasks-list li.score-7 a { background-color: #d35400; } /* Orange */
.tasks-list li.score-10 a { background-color: #c0392b; } /* Red */

/* Delete button styling */
.tasks-list li button {
    position: absolute;
    background-color: #444; /* Red background */
    color: white; /* White text color */
    padding: 5px 10px; /* Padding inside the button */
    right: 10px; /* 10px from the right edge */
    top: 40px; 
    border: none; /* No border */
    border-radius: 3px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
    margin-top: 10px; /* Space above the delete button */
}

.tasks-list li button:hover {
    background-color: #c0392b; /* Darker red on hover */
}

/* Add New Issue Form styling */
.new-task-form {
    background: #ecf0f1; /* Light grey background */
    padding: 20px; /* Padding inside the form */
    margin-bottom: 20px; /* Space below the form */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}



.new-task-form input[type="text"],
.new-task-form textarea,
.new-task-form select {
    width: calc(100% - 20px); 
    padding: 5px 8px; /* Further reduced padding for small height */
    margin-bottom: 10px; /* Space below inputs */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 3px; /* Rounded corners */
    font-size: 0.9rem; /* Slightly smaller font size */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
}
.new-task-form textarea{
    margin: 0;
}
.new-task-form button {
    background-color: #3498db; /* Blue background */
    color: white; /* White text color */
    padding: 5px 10px;
    border: none; /* No border */
    border-radius: 3px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.new-task-form button:hover {
    background-color: #2980b9; /* Darker blue on hover */
}

/* AccountabilityHeader.css */
.document-header {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin: 12px;
  }
  
  .document-header h1, .document-header h2 {
    color: #2c3e50;
    margin-bottom: 10px;
    margin-top: 0;
  }
  
  .document-header p {
    color: #666;
    line-height: 1.6;
    margin: 10px 0;
  }

  .header-flex {
    display: flex;
    justify-content: space-between; /* Aligns children at the start and end of the container */
    align-items: center; /* Aligns children vertically in the middle */
}

.header-flex p {
    margin: 0; /* Removes default paragraph margins */
    padding: 0.5em; /* Adds a bit of padding around the text for better readability */
}


